import React, { useState, useEffect, useRef } from "react";
import { functions } from "../../config/fbConfig";
import firebase from "../../config/fbConfig";
import { httpsCallable } from "firebase/functions";
import LayoutInSide from "../../layouts/LayoutInSide";
import { UserData, getAllUserData } from "../../utils/userUtils";
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from "react-toastify";
import { usePostHog } from 'posthog-js/react';
import { auth } from "../../config/fbConfig";
import { UserGuideStepEnum } from "../../consts";
import { useShareableData } from "../../providers/ShareableDataProvider";
import ReactMarkdown from 'react-markdown';
import UpgradeModal from "../../layouts/Modal/UpgradeModal";
import PulsingMessageSkeleton from "../../components/loader/message_skeleton";
import { Loader2 } from "lucide-react";

// Define the expected shape of the result data
interface ResultData {
  error?: string;
  sessionToken?: string;
}

const chat = httpsCallable(functions, "chat", { timeout: 300000 });

function ChatPage() {
  // Interface for the chat messages
  interface Message {
    content: string;
    type: string;
  }

  const [text, setText] = useState("");
  const [chatData, setChatData] = useState<Message[]>([]);
  const sessionTokenRef = useRef<string>("");
  // Boolean to check if we are waiting for an answer
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [userData, setUserData] = useState<UserData>({ "userData": "test", "productData": "test" });
  const { showOnBoarding, completeOnBoardingStep} = useShareableData();
  const posthog = usePostHog();
  const [showCopyButton, setShowCopyButton] = useState(-1);
  const [showIndicator, setShowIndicator] = useState(false);
  // Helper ref and function to scroll to the bottom of the chat
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const initialized = useRef(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [currentChatId, setCurrentChatId] = useState<string | null>(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  // Functions
  const init_chat = httpsCallable(functions, "initialize_chat");

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [chatData]);

  useEffect(() => {
    loadUserData();
  }, []);

  // useEffect to instantiate a new chat session using the init_chat callable function
  // only called when the sessionToken is empty
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionTokenParam = urlParams.get('sessionToken');

    if (sessionTokenParam) {
      sessionTokenRef.current = sessionTokenParam;
      setCurrentChatId(sessionTokenParam);
    } else if (!sessionTokenRef.current && !initialized.current) {
      initialized.current = true;
      const startTime = performance.now();
      init_chat()
        .then((result) => {
          const newSessionToken = (result.data as ResultData).sessionToken;
          if (newSessionToken) {
            sessionTokenRef.current = newSessionToken;
            setCurrentChatId(newSessionToken);
          } else {
            console.error("Failed to retrieve session token");
          }
          posthog.capture('chat_page:new_chat', {
            thread_id: newSessionToken,
            load_time_ms: performance.now() - startTime
          });
        })
        .catch((error) => {
          console.error("Chat session failed to initialize:", error);
          // Show an error message to the user
        });
    } else {
    }
  //eslint-disable-next-line
  }, []);

  // Function to handle the suggestion buttons
  const handleSuggestionClick = async (suggestion: string) => {
    // Write the suggestion to the chat, character by character
    for (let i = 0; i < suggestion.length; i++) {
      setText((prevText) => prevText + suggestion[i]);
      await new Promise((r) => setTimeout(r, 10));
    }

    // Add the suggestion to the chat data as a user message
    setChatData([...chatData, { content: suggestion, type: 'user' }]);

    // Use optional parameter to send the suggestion as a message
    handleMessageSubmit(suggestion);

    setText("");

    // Posthog Tracking
    posthog.capture('chat_page:suggestion_click', {
      thread_id: sessionTokenRef.current,
      suggestion: suggestion
    });
  };

  // Function to upload the user's message to the chat
  const handleMessageSubmit = async (message?: string) => {
    try {
      setButtonDisabled(true);
      setIsWaitingForResponse(true);

      // Wait for the session token to be available
      while (!sessionTokenRef.current) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }


      // Use the provided message or fall back to the 'text' variable
      const messageToSend = message ?? text;

      const data = { "sessionToken": sessionTokenRef.current, "message": messageToSend };
      const result = await chat(data) as { data: ResultData };

      // Check if 'error' key exists in the response and if it's true
      if (result.data && result.data.hasOwnProperty('error') && result.data.error) {
        var errorText: string = "Etwas ist schief gelaufen. Bitte versuchen sie es später noch einmal.";
        if (result.data.error === "Max words exceeded") {
          errorText = "Die eingegebene Nachricht ist zu lang. Bitte versuchen Sie es mit einer kürzeren Nachricht erneut.";
        } else if (result.data.error === "Message while active run") {
          errorText = "Es können keine Nachrichten gesendet werden, während der Chat aktiv ist. Bitte warten Sie auf eine Antwort.";
        } else if (result.data.error === "Not enough words left") {
          setShowUpgradeModal(true);
          setButtonDisabled(false);
          return;
        }
        toast.error(errorText, {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setButtonDisabled(false);
        return true;
      }

      posthog.capture('chat_page:message_submit', {
        thread_id: sessionTokenRef.current
      });

      if(showOnBoarding){
        completeOnBoardingStep(UserGuideStepEnum.CHAT)
      };

      setButtonDisabled(false);
    } catch (error) {
      console.error("Error submitting message:", error);
      toast.error("Failed to send message. Please try again.");
      setButtonDisabled(false);
      setIsWaitingForResponse(false);
    }
  };

  // Continuously load the chat data from firestore
  useEffect(() => {
    if (!currentChatId) return;

    const db = firebase.firestore();
    const docRef = db.collection('users').doc(auth.currentUser!.uid).collection('chat').doc(currentChatId);


    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data) {
          if (chatData.length >= 2 || data.messages.length >= 2) {
            setChatData(data.messages);
          }
          if (isWaitingForResponse && data.messages.length > chatData.length) {
            const lastMessage = data.messages[data.messages.length - 1];
            if (lastMessage.type === 'assistant' && lastMessage.content.trim() !== '') {
              setIsWaitingForResponse(false);
            }
          }
        }
      }
    }, (error) => {
      console.error(`Firestore listener error for chat ${currentChatId}:`, error);
    });

    return () => {
      unsubscribe();
    };
  //eslint-disable-next-line
  }, [currentChatId, isWaitingForResponse]);

  const loadUserData = async () => {
    let retrievedUserData = await getAllUserData(auth.currentUser!.uid);
    setUserData(retrievedUserData);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  return (
    <LayoutInSide userData={userData}>
      {/* Chat container */}
      <div className="col-1 xl:max-w-975 w-auto flex flex-col border border-grey shadow bg-white rounded mx-auto mb-4 -mt-5 min-h-[300px] h-[calc(90vh)] md:h-[calc(90vh)] h-[80vh]">

        {/* Chat Content */}
        <div className="flex-grow overflow-hidden">
          <div ref={messagesEndRef} className="h-full overflow-y-auto p-4 custom-scrollbar">
            <style>{`
              .custom-scrollbar::-webkit-scrollbar {
                width: 10px;
              }
    
              .custom-scrollbar::-webkit-scrollbar-track {
                background: transparent; 
              }
    
              .custom-scrollbar::-webkit-scrollbar-thumb {
                background: #888; 
                border-radius: 10px;
              }
    
              .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                background: #555; 
              }
    
              /* Buttons */
              .custom-scrollbar::-webkit-scrollbar-button {
                display: none;
              }
            `}</style>
            {chatData.length === 0 && !buttonDisabled ? (
              // Render initial suggestions
              <div className="flex-grow flex items-center justify-center h-full">
                <div className="text-center">
                  <h3 className="mt-4 text-lg md:text-xl">Wie kann ich Dir heute helfen?</h3>

                  { /* Render three Suggestions below the text, similar to chatgpt. They each have an icon, a muted color, and will prefill the textfield when clicked. */}
                  <div className="flex flex-col md:flex-row gap-2 mt-4">
                    <button
                      className="flex flex-row sm:flex-col items-center gap-2 p-2 rounded bg-gray-100 hover:bg-gray-200 border-2 border-purple-500 border-opacity-50 w-full h-auto sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40"
                      onClick={() => handleSuggestionClick("Schreibe eine Geschichte für mich, frage mich nach dem Genre und dem Hauptcharakter.")}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="rgb(237, 98, 98)" viewBox="0 0 24 24" className="w-10 h-10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19a3 3 0 1 1-6 0M15.865 16A7.54 7.54 0 0 0 19.5 9.538C19.5 5.375 16.142 2 12 2S4.5 5.375 4.5 9.538A7.54 7.54 0 0 0 8.135 16m7.73 0h-7.73m7.73 0v3h-7.73v-3"></path>
                      </svg>
                      <p className="text-sm md:text-base">Schreibe eine Geschichte</p>
                    </button>
                    <button
                      className="flex flex-row sm:flex-col items-center gap-2 p-2 rounded bg-gray-100 hover:bg-gray-200 border-2 border-purple-500 border-opacity-50 w-full h-auto sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40"
                      onClick={() => handleSuggestionClick("Plane einen Urlaubstag für mich, frage mich nach meinen Interessen und dem Urlaubsziel.")}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="rgb(203, 139, 208)" viewBox="0 0 24 24" className="w-10 h-10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9.65 13.026-3.287 1.19A2 2 0 0 1 3.8 13.027l-.342-.934.597-1.275L1.75 7.419l2.348-.85 2.564 1.484a2 2 0 0 0 1.689.15l8.512-3.083c.291-.106.603-.142.912-.107l2.833.325a1.842 1.842 0 0 1 .422 3.565l-5.276 1.911m.598-1.275L13 14.5l-2.817 1.02-.343-3.622"></path>
                      </svg>
                      <p className="text-sm md:text-base">Plane einen Urlaubstag für mich</p>
                    </button>
                    <button
                      className="flex flex-row sm:flex-col items-center gap-2 p-2 rounded bg-gray-100 hover:bg-gray-200 border-2 border-purple-500 border-opacity-50 w-full h-auto sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40"
                      onClick={() => handleSuggestionClick("Antworte auf eine Email für mich, frage mich nach dem Betreff und dem Inhalt, und auf welche Art ich antworten soll.")}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="rgb(163, 72, 92)" viewBox="0 0 24 24" className="w-10 h-10">
                        <path fill="currentColor" d="M20.5,4h-17C2.673,4,2,4.673,2,5.5v13C2,19.327,2.673,20,3.5,20h17c0.827,0,1.5-0.673,1.5-1.5v-13C22,4.673,21.327,4,20.5,4z M20,7.153l-7.898,6.152c-0.349,0.273-0.847,0.273-1.196,0L4,7.153V6h16V7.153z"></path>
                      </svg>
                      <p className="text-sm md:text-base">Antworte auf eine Email</p>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              // Render chat messages
              chatData.map((message, index) => {
                // Don't render the last message if it's from the assistant and we're waiting for a response
                if (isWaitingForResponse && index === chatData.length - 1 && message.type === 'assistant') {
                  return null;
                }
                if (message.content === '') {
                  return null;
                }
                return (
                  <div
                    key={index}
                    className={`relative flex items-start pb-2 mb-2 px-4 ${message.type === 'assistant' ? 'bg-gray-50 rounded-lg' : ''}`}
                    onMouseEnter={() => setShowCopyButton(index)}
                    onMouseLeave={() => setShowCopyButton(-1)}
                  >
                    <img
                      src={message.type === 'user' ? '/assets/images/user_icon.jpg' : '/assets/images/logo-0.svg'}
                      alt="icon"
                      className="mr-4 w-6 h-auto rounded-full sm:block hidden"
                    />
                      <ReactMarkdown className="flex-grow whitespace-pre-wrap">{message.content}</ReactMarkdown>
                    {showCopyButton === index && window.innerWidth > 768 && (
                      <div className="absolute right-0 top-0 ml-2">
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(message.content);
                            setShowIndicator(true);
                            setTimeout(() => setShowIndicator(false), 2000);
                            posthog.capture('chat_page:copy_message', {
                              thread_id: sessionTokenRef.current
                            });
                          }}
                        >
                          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.016 0H7.5114C5.9238 0 4.6656 0 3.6819 0.1332C2.6685 0.27 1.8486 0.558 1.2024 1.2069C0.5553 1.8558 0.2682 2.6793 0.1323 3.6963C6.70552e-08 4.6845 0 5.9472 0 7.5411V12.7953C0 14.1525 0.828 15.3153 2.0043 15.8031C1.944 14.9841 1.944 13.8366 1.944 12.8808V8.3718C1.944 7.2189 1.944 6.2244 2.0502 5.4288C2.1645 4.5756 2.4219 3.7584 3.0825 3.0951C3.7431 2.4318 4.5576 2.1735 5.4072 2.0583C6.1992 1.9521 7.1892 1.9521 8.3385 1.9521H11.1015C12.2499 1.9521 13.2381 1.9521 14.031 2.0583C13.7934 1.45204 13.3787 0.931405 12.8409 0.564261C12.3031 0.197118 11.6672 0.000488517 11.016 0Z" fill="grey" />
                            <path d="M3.24023 8.45731C3.24023 6.00391 3.24023 4.77721 3.99983 4.01491C4.75853 3.25261 5.97983 3.25261 8.42423 3.25261H11.0162C13.4597 3.25261 14.6819 3.25261 15.4415 4.01491C16.2002 4.77721 16.2002 6.00391 16.2002 8.45731V12.7953C16.2002 15.2487 16.2002 16.4754 15.4415 17.2377C14.6819 18 13.4597 18 11.0162 18H8.42423C5.98073 18 4.75853 18 3.99983 17.2377C3.24023 16.4754 3.24023 15.2487 3.24023 12.7953V8.45731Z" fill="grey" />
                          </svg>
                        </button>
                        <div
                          className={`absolute top-full right-0 mt-2 p-2 bg-green-200 text-green-700 border border-green-300 rounded shadow-lg 
                                    transition-all duration-1000 ease-in-out transform w-100px
                                    ${showIndicator ? 'opacity-100' : 'hidden'}`}
                        >
                          Erfolgreich kopiert!
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}
            {/* Render loading skeleton while waiting for response */}
            {isWaitingForResponse && (
              <div className="relative flex items-start pb-2 mb-2 px-4">
                <img
                  src="/assets/images/logo-0.svg"
                  alt="icon"
                  className="mr-4 w-6 h-auto rounded-full sm:block hidden"
                />
                <div className="flex-grow">
                  <PulsingMessageSkeleton />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Input area */}
        <div className="flex items-center gap-4 border-t mx-2 p-2 mt-auto sticky bottom-0 bg-white">
          <TextareaAutosize
            className="flex-grow block rounded-lg border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:text-sm overflow-auto resize-none pr-5 whitespace-pre-wrap"
            value={text}
            onInput={handleInputChange}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                if (text.trim() !== "" && !buttonDisabled) {
                  setChatData([...chatData, { content: text, type: 'user' }]);
                  handleMessageSubmit();
                  setText("");
                }
              }
            }}
            maxRows={20}
          />
          <div className="text-center relative">
            {buttonDisabled ? (
              <div className="h-12 w-12 flex items-center justify-center">
                <Loader2 className="animate-spin h-6 w-6" />
              </div>
            ) : (
              <button 
                type="submit" 
                className="h-12 w-12 rounded bg-secondary text-white text-md hover:bg-secondary-600" 
                onClick={() => { 
                  if (text.trim() !== "") { 
                    setChatData([...chatData, { content: text, type: 'user' }]); 
                    handleMessageSubmit(); 
                    setText(""); 
                  } 
                }}
              >
                ↑
              </button>
            )}
          </div>
        </div>
      </div>
      <UpgradeModal isOpen={showUpgradeModal} onRequestClose={() => {setShowUpgradeModal(false)}} />  
    </LayoutInSide>
  );
}

export default ChatPage;
