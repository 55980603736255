import { default as firebase } from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  addDoc,
  getDocs,
  serverTimestamp,
  deleteDoc,
  updateDoc
} from "firebase/firestore";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: "G-RR4L40RQQW",
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
//firestore.settings({ timestampsInSnapshots: true, merge: true });
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "europe-west3");

// This should only be true in local development and CI builds.
// ref: https://firebase.google.com/docs/app-check/web/debug-provider#web-modular-api
// This enables App Check in debug mode for local dev
if (process.env.REACT_APP_CHECK_DEBUG_TOKEN) {
  console.log("App Check Debug Token is set, enabling App Check debug mode.")
  global.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_CHECK_DEBUG_TOKEN;
}

// Set the site key, is set via GH Vars, depending on the env
const siteKey = process.env.REACT_APP_RECAPCHA_SITE_KEY;

// Initialize the App Check service
// Create a ReCaptchaEnterpriseProvider instance using reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
// The captcha provider is set here:
// https://www.google.com/u/3/recaptcha/admin/site/702927758
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(siteKey),
  isTokenAutoRefreshEnabled: true // Seet to true to allow auto-refresh.
});

// Initialize the Vertex AI service
export const vertexAI = getVertexAI(app);

// Use Gemini 1.5 Flash, to get the fastest available model
export const gemini_model = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro-002" });

export { collection, doc, getDoc, setDoc, onSnapshot, addDoc, getDocs, serverTimestamp, deleteDoc, updateDoc, firebaseConfig };
export default firebase;