import React from "react";

type Props = {
  children: React.ReactNode;
  paddingZero?: boolean;
};

const Container = (props: Props) => {
  return (
    <div
      className={`container mx-auto ${
        props.paddingZero ? "p-0" : "px-2 sm:px-4 lg:px-6 xl:px-8"
      }`}
    >
      {props.children}
    </div>
  );
};

export default Container;